import { string } from 'prop-types';
import React from 'react';
import css from './CarouselElement.module.css';

const CarouselElement = props => {
  const { imgSrc, paragraph, nameUpper, nameLower, id } = props;

  return (
    <div className={css.carouselElementWrapper} id={id}>
      <div className={css.sectionInner}>
        <div className={css.imageWrapper}>
          <img src={imgSrc} className={css.image} alt="Carousel Avatar" />
          <cite className={css.nameUpper}>{nameUpper}</cite>
          {nameLower && <footer>{nameLower}</footer>}
        </div>
        <div className={css.textWrapper}>
          <span style={{ whiteSpace: 'pre-line' }}>{paragraph}</span>
        </div>
      </div>
    </div>
  );
};

CarouselElement.defaultProps = {
  imgSrc: null,
  paragraph: null,
  nameUpper: null,
  nameLower: null,
  visible: string,
};

CarouselElement.propTypes = {
  imgSrc: string,
  paragraph: string,
  nameUpper: string,
  nameLower: string,
  id: string,
};

export default CarouselElement;
