import React, { useMemo } from 'react';
import css from './ScrollDots.module.css';

const ScrollDots = props => {
  const { items, currentItem } = props;

  const dots = useMemo(() => {
    var newDots = [];
    for (let index = 0; index < items; index++)
      newDots.push(
        <div
          key={`dotsDiv${index}`}
          className={index === currentItem ? `${css.dot} ${css.dotActive}` : css.dot}
        />
      );
    return newDots;
  }, [items, currentItem]);

  return <div className={css.scrollDotsWrapper}>{dots}</div>;
};

export default ScrollDots;
