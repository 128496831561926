import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import arrow from './arrow.svg';
import CarouselElement from './CarouselElement';
import css from './ImageWithTextCarousel.module.css';
import ScrollDots from './ScrollDots';

const ImageWithTextCarousel = ({ carouselItems, id = 'carousel' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isProgrammaticScroll, setIsProgrammaticScroll] = useState(false);
  const carouselRef = useRef(null);

  useEffect(() => {
    const slideCount = carouselItems.length;
    const carouselElement = document.getElementById(`carouselItemsWrapper${id}`);
    if (carouselElement) {
      carouselElement.style.setProperty('--slide-count', slideCount.toString());
    }
  }, [carouselItems, id]);

  const elements = useMemo(
    () =>
      carouselItems.map(item => (
        <CarouselElement
          key={item.id}
          id={`${id}-${item.id}`}
          imgSrc={item.imgSrc}
          nameUpper={item.nameUpper}
          nameLower={item.nameLower}
          paragraph={item.paragraph}
        />
      )),
    [carouselItems, id]
  );

  const calculateNewIndex = direction => {
    let newIndex;
    if (direction === 'prev') {
      newIndex = currentIndex - 1 < 0 ? elements.length - 1 : currentIndex - 1;
    } else {
      newIndex = (currentIndex + 1) % elements.length;
    }
    return newIndex;
  };

  const scrollToElement = newIndex => {
    const carousel = carouselRef.current;
    if (carousel) {
      const slideWidth = carousel.scrollWidth / carouselItems.length;
      let newScrollPosition = slideWidth * newIndex;
      let behavior = 'smooth';

      if (currentIndex === 0 && newIndex === carouselItems.length - 1) {
        newScrollPosition = 0;
        behavior = 'auto';
      } else if (currentIndex === carouselItems.length - 1 && newIndex === 0) {
        newScrollPosition = slideWidth * carouselItems.length;
        behavior = 'auto';
      }

      carousel.scroll({ left: newScrollPosition, behavior: behavior });
      setIsProgrammaticScroll(true);

      setTimeout(
        () => {
          setIsProgrammaticScroll(false);
          if (behavior === 'auto') {
            carousel.scroll({ left: slideWidth * newIndex, behavior: 'auto' });
          }
          setCurrentIndex(newIndex);
        },
        behavior === 'smooth' ? 50 : 0
      );
    }
  };

  const prevElement = useCallback(() => {
    const newIndex = calculateNewIndex('prev');
    scrollToElement(newIndex);
  }, [currentIndex, elements.length]);

  const nextElement = useCallback(() => {
    const newIndex = calculateNewIndex('next');
    scrollToElement(newIndex);
  }, [currentIndex, elements.length]);

  const onScroll = useCallback(() => {
    if (isProgrammaticScroll) return;

    const carousel = carouselRef.current;
    if (carousel) {
      const elementWidth = carousel.scrollWidth / carouselItems.length;
      const newIndex = Math.round(carousel.scrollLeft / elementWidth);
      if (newIndex !== currentIndex) {
        setCurrentIndex(newIndex);
      }
    }
  }, [currentIndex, carouselItems.length, isProgrammaticScroll]);

  useEffect(() => {
    const carousel = carouselRef.current;
    carousel.addEventListener('scroll', onScroll);
    return () => {
      carousel.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  return (
    <div className={css.carouselWrapper} id={`carouselWrapper${id}`}>
      <img onClick={prevElement} className={css.prev} src={arrow} alt="Previous" />
      <div ref={carouselRef} id={`carouselItemsWrapper${id}`} className={css.carouselItemsWrapper}>
        {elements}
      </div>
      <div className={css.dotsContainer}>
        <img onClick={prevElement} className={css.prevMobile} src={arrow} alt="Previous" />
        <ScrollDots items={elements.length} currentItem={currentIndex} />
        <img onClick={nextElement} className={css.nextMobile} src={arrow} alt="Next" />
      </div>
      <img onClick={nextElement} className={css.next} src={arrow} alt="Next" />
    </div>
  );
};

export default ImageWithTextCarousel;
