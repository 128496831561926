import React, { useState, useEffect, Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { lazyLoadWithDimensions } from '../../../util/uiHelpers';
import css from './SectionChildcareLayers.module.css';

import regularImg from './images/regular.png';
import occasionalImg from './images/occasional.png';
import lastMinuteImg from './images/lastMinute.png';
import crecheImg from './images/creche.png';
import maternityImg from './images/maternity.png';
import overnightImg from './images/overnight.png';
import activityImg from './images/activity.png';
import onlineImg from './images/online.png';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const getCategory = (name, imageSrc) => {
  const nameText = <span className={css.locationName}>{name}</span>;

  return (
    <div className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={imageSrc} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>{nameText}</div>
    </div>
  );
};

const SectionChildcareLayers = () => {
  const animationStyle = 'style2';
  const categories = [
    { name: 'Regular Minders™', image: regularImg },
    { name: 'Back Up Minders™', image: lastMinuteImg },
    { name: 'Occasional Minders™', image: occasionalImg },
    { name: 'Creche & Daycare Places', image: crecheImg },
    { name: 'Pregnancy & Birth \n(Multiple Layers)', image: maternityImg },
    { name: 'Overnight Minders™', image: overnightImg },
    { name: 'Activities & Camps', image: activityImg },
    { name: 'Online & In-person Tutors', image: onlineImg },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => window.innerWidth < 769;
    setIsMobileView(checkIfMobile());

    const handleResize = () => {
      setIsMobileView(checkIfMobile());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % categories.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const renderCategory = index => {
    // Calculate the index for each card, wrapping around if necessary
    const categoryIndex = (index + categories.length) % categories.length;
    const category = categories[categoryIndex];
    let className = css.wrapCard;

    let isFocusCard;
    if (animationStyle === 'style1') {
      isFocusCard = index === currentSlide + 3 || (isMobileView && index === currentSlide);
    } else if (animationStyle === 'style2') {
      isFocusCard = index === currentSlide;
    } else {
      throw new Error('Invalid animation style');
    }

    className = classNames(className, {
      [css.focusCard]: isFocusCard,
      [css.zoomOut]: !isFocusCard,
    });

    return (
      <div className={className} key={category.name}>
        {getCategory(category.name, category.image)}
      </div>
    );
  };

  return (
    <div className={css.sectionContainer}>
      <div className={css.sectionContainer}>
        <h2 className={css.title}>8 Layers of Childcare</h2>
        <p className={css.subtitle}>4 every stage of parenting</p>
        <div className={css.carouselContainer}>
          <div className={css.locations}>
            {animationStyle === 'style1'
              ? Array(8)
                  .fill()
                  .map((_, i) => renderCategory(currentSlide + i))
              : categories.map((_, i) => renderCategory(i))}
          </div>
        </div>
      </div>
    </div>
  );
};

SectionChildcareLayers.propTypes = {
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  animationStyle: PropTypes.oneOf(['style1', 'style2']),
};

SectionChildcareLayers.defaultProps = {
  rootClassName: null,
  className: null,
  animationStyle: 'style1',
};

export default SectionChildcareLayers;
